<template>
  <div class="timer-button">
    <router-link
                 :disabled="disabled"
                 :to="{name: link}" >
      <span>{{ text }}</span>
      <span class="countDown">{{ timeLeftString }}</span>
    </router-link>
  </div>
</template>

<script>
import moment from 'moment'
import {useAnketaStore} from "@/stores/anketa";
import anketaMixin from "@/mixins/anketaMixin";
import {ref} from "vue";
import {useHeaderStore} from "@/stores/header";

export default {
  name: "timer-button",
  props: ['text', 'link', 'disabled'],
  mixins: [anketaMixin],
  setup() {
    const { setValue,getStepData,steps } = useAnketaStore();
    const defaultTimer = 86359;
    let timerStore = ref(getStepData(steps.countDown) || defaultTimer)
    const notifications = ref(getStepData("Notifications"))
    const store = useHeaderStore()
    const {showBackButton} = store;
    return {
      showBackButton, setValue,getStepData,steps,timerStore,notifications
    };
  },
  data() {
    return {
      timeLeft: 0,
      timeLeftString: '',
      timer: null
    }
  },
  watch: {
    timeLeft: function (val) {
      const timeZero = moment("1900-01-01 00:00:00");
      this.timeLeftString = timeZero.add(val, 'seconds').format("HH : mm : ss")
    },
  },
  mounted() {
    console.log('update timer')
    this.timeLeft = this.timerStore;
    this.timer = setInterval(() => {
      if (this.timeLeft <= 0) {
        clearInterval(this.timer);
      } else {
        this.timeLeft--
        this.setValue(this.steps.countDown,this.timeLeft)
      }
    }, 1000)
  },
  unmounted() {
    this.timeLeft= null
  },
  methods: {
    play() {
      this.timerEnabled = true;
    },
    pause() {
      this.timerEnabled = false;
    }
  }
}
</script>
