import {defineStore} from 'pinia'
import common from '@/composables/common'
import {fetchWrapper} from "@/helpers/auth";

export const useAnketaStore = defineStore('anketa', {
    state: () => (
        {
            anketa: [],
            steps: {
                step1: "hair_color",
                step2: "eye_color",
                step3: "skin_tone",
                step4: "body_type",
                step5: "face_feature",
                step6: "style",
                step7: "height",
                step8: "top_size",
                step9: "bottom_size",
                last: null,
                finished: false,
                Notifications: false,
                countDown: "countDown",
            },
            settings: [],
        }
    ),
    getters: {
        getSettings(state) {
            return state.getSettings
        },
    },
    actions: {
        save() {
            common.saveToLocalStorage("anketa", this.anketa)
        },
        getAnketa(){
            let apiData = {}
            for(const el of this.anketa) {
                switch (el.step) {
                    case 'style': case 'face_feature':
                        apiData[el.step] = [...el.value]
                        break;
                    default:
                        apiData[el.step] = el.value
                        break;
                }
            }
            return apiData;
        },

        getStepData(step) {
            let data = false
            try {
                if (this.anketa) {
                    var i = this.anketa.findIndex(function (e) {
                        return e.step == step;
                    });
                    i != -1 ? data = this.anketa[i].value : data;
                }
                return data;
            } catch (e) {
                return data;
            }
        },

        isFinished() {
            let result = false
            try {
                if (this.anketa) {
                    var i = this.anketa.findIndex(function (e) {
                        return e.step == 'finished';
                    });

                    if (i != -1){
                        if (this.anketa[i].value === true)
                            result = true
                    }
                }
                return result;
            } catch (e) {
                return result;
            }
        },

        setValue(step, value, name) {
            common.addOrReplace(this.anketa, {step: step, value: value, name: name})
            this.save();
        },

        saveLastStep(step){
            common.addOrReplace(this.anketa, {step: 'last', value: step})
            this.save();
        },

        async postAnketa(){
            const data = this.getAnketa()
            const api = await fetchWrapper.post('/form/', data).then(async function (){

                const api = await fetchWrapper.get('/collection/').catch(function (e){
                    console.log(e)
                });
                if (api) {
                    common.saveToLocalStorage("collection",api)
                    return api
                } else {
                    console.log('Error: fetch api collection')
                    return {}
                }
            });
        }
    },

})
