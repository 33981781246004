import {defineStore} from 'pinia'
import {fetchWrapper} from '@/helpers/auth';
import common from "@/composables/common";
import router from "@/router";
import {useAppStore} from "@/stores/app";
import {useAnketaStore} from "@/stores/anketa";

export const useSettingsStore = defineStore('settings', {
    state: () => (
        {
            settings: JSON.parse(localStorage.getItem("settings")) || null,
            returnUrl: null
        }
    ),
    actions: {
        async fetchApiSettings() {
            const app = useAppStore();

            if (this.settings === null) {
                try {
                    const api = await fetchWrapper.get('/settings/')
                        .catch(function (){
                            console.log("Error: fetch api settings")
                            return false;
                    });


                    common.saveToLocalStorage("settings", api.form)
                    this.settings = JSON.parse(localStorage.getItem("settings"))
                    return this.settings;
                } catch (e) {
                    return router.push('/anketa/fillout')
                }
            } else {
                app.setLoading(false);
                return true;
            }
        },

        async postUserAnketa(anketa) {
            try {
                const api = await fetchWrapper.post('/form/', anketa);
            } catch (e) {
                return router.push({name: "anketa"})
            }
        },

        addToAnketa(api){
            const anketa = useAnketaStore();
                anketa.setValue("hair_color", api.hair_color, "hair_color")
                anketa.setValue("eye_color", api.eye_color, "eye_color")
                anketa.setValue("skin_tone", api.skin_tone, "skin_tone")
                anketa.setValue("body_type", api.body_type, "body_type")
                anketa.setValue("face_feature", api.face_feature, "face_feature")
                anketa.setValue("style", api.style, "style")
                anketa.setValue("height", api.height, "height")
                anketa.setValue("top_size", api.top_size, "top_size")
                anketa.setValue("bottom_size", api.bottom_size, "bottom_size")
                anketa.setValue("last", null, null)
                anketa.setValue("finished", api.finished || false, null)
                anketa.setValue("countDown", null, null)

        },

        async getUserAnketa() {
            try {
                return await fetchWrapper.get('/form/').then((api)=>{
                    if (! api.detail ) {
                        localStorage.setItem('anketa', JSON.stringify(api));
                        this.addToAnketa(api)
                        return true;
                    } else {
                        return false;
                    }
                }).finally()

            } catch (e) {
                console.log("Редирект на заполнение анкеты")
                return false;

            }
        },

        parseSettings(val){
            let result = false
            var i = this.settings.findIndex(function (e) {
                return e.name == val;
            });
            i != -1
                ? result = this.settings[i].data.choices
                : result;
            return result;
        },

        getSetting(val) {
            try {
                if (!this.settings)
                    return this.fetchApiSettings().then(() => this.parseSettings(val))
                else
                    return this.parseSettings(val)
            } catch (e) {

            }

        }
    }

})
