<template>
  <div v-if="pushModel === false && subscribed == false" class="push-notifications" @click="$emit('notifications',{pushModel})">
        <span class="left">
          <span class="title">{{ title }}</span>
          <span class="description">{{ description }}</span>
        </span>
    <Switch
        label=""
        v-model:checked="pushModel"
        @toggle="notifications"

    />
  </div>
</template>
<script>
import Switch from "@/components/UI/Switch"
import {ref} from "vue";
import {useAnketaStore} from "@/stores/anketa";
import appMixin from "@/mixins/appMixin";
import {mapState} from "pinia";
import {useUserStore} from "@/stores/users";

export default {
  name: 'push-notifications',
  components: {Switch},
  mixins: [appMixin],
  props: {
    title: {},
    description: {},
  },
  setup() {
    const pushModel = ref(false)
    const {getStepData} = useAnketaStore();
    const notifications = ref(getStepData("Notifications"))
    const {setSubscribed} = useUserStore();
    return {pushModel,notifications,setSubscribed};
  },
  watch:{
    pushModel(val){
      this.appEvent('onSubscribeToNotifications')
      this.setSubscribed(true)
    }
  },
  computed:{
    ...mapState(useUserStore,['subscribed'])
  },

}
</script>
