<template>
  <div class="header">
    <a v-if="!header.backLink" @click="handleBackClick">
      <div class="back" v-if="header.back"></div>
    </a>

    <a v-else @click="$router.push({name: header.link})">
      <div class="back" ></div>
    </a>

    <span class="title">{{ header.title }}
    </span>
    <div class="link" v-if="!header.removeClose"
         @click="$router.push({name:'home'});appEvent('onAppClose')">

    </div>
  </div>
</template>

<script>
import { useHeaderStore } from '@/stores/header'
import {useRoute} from "vue-router";
import appMixin from "@/mixins/appMixin";
import router from "@/router";
import {mapState} from "pinia";
import {useUserStore} from "@/stores/users";

export default {
  name: 'Header',
  mixins: [appMixin],
  setup() {
    const store = useHeaderStore()
    const { header } = store;
    const route = useRoute()
    return {header,route}
  },
  computed: {
    ...mapState(useUserStore, ['user','subscribed']),
  },
  methods: {
    handleBackClick() {
      console.log(this.$route.name)
      if (this.$route.name === 'anketa.completion') {
        return router.push(`/?clientHash=${this.user.hash}&clientName=${this.user.name}&subscribed=${this.subscribed}`)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>


<style scoped>

</style>